import { Tooltip } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { Employee } from '../../generated/graphql';
import {
  employeeFullname,
  employeeInitials,
  employeeIsFreelancer,
  employeeIsNonBillable
} from '../../models/EmployeeTypes';
import { Avatar, AvatarProps } from './Avatar';

type EmployeeAvatarProps = {
  employee: Employee;
  active?: boolean;
} & AvatarProps;

export const EmployeeAvatar = ({
  employee,
  size,
  notification,
  active = true
}: EmployeeAvatarProps) => {
  var circleColor = 'p-[2px] rounded-full';
  if (employeeIsFreelancer(employee)) {
    circleColor += ' bg-blue-500';
  } else if (employeeIsNonBillable(employee)) {
    circleColor += ' bg-green-500';
  } else {
    circleColor = '';
  }
  return (
    <Tooltip
      content={`${employeeFullname(employee)} ${
        employeeIsFreelancer(employee) ? ' - Freelancer' : ''
      }`}
    >
      <Avatar
        url={employee.avatar}
        size={size}
        notification={notification}
        //borderColor={active || active === undefined ? undefined : 'border-red-700 border-2'}
        borderColor={circleColor}
        //className={circleColor}
        fallback={employeeInitials(employee)}
      />
    </Tooltip>
  );
};

type EmployeeAvatarAndNameProps = {} & EmployeeAvatarProps;

export const EmployeeAvatarAndName = (props: EmployeeAvatarAndNameProps) => {
  return (
    <Link to={`/people/${props.employee.id}`} className='flex items-center '>
      <EmployeeAvatar {...props} />
      <div className='self-center ml-3 text-neutral-800 overflow-clip whitespace-nowrap text-ellipsis'>
        {employeeFullname(props.employee)}
      </div>
    </Link>
  );
};
