import {
  ArrowPathIcon,
  MinusCircleIcon,
  PencilSquareIcon,
  PlusCircleIcon
} from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { DeleteModal } from '../../components/Modals/DeleteModal';
import { Spinner } from '../../components/Spinner/Spinner';
import {
  dateColumnGenerator,
  hoursColumnGenerator,
  jsonStringifierColumnGenerator,
  numberColumnGenerator,
  stringColumnGenerator
} from '../../components/Tables/ColumnTypes';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import Toggle from '../../components/Toggle/Toggle';
import {
  Balance_Corrections,
  Employee,
  Employee_Allocations,
  useDeleteBalanceCorrectionMutation,
  useDeleteEmployeeAllocationMutation,
  useEmployeesDetailsQuery
} from '../../generated/graphql';
import { employeeFullname } from '../../models/EmployeeTypes';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { formatDate } from '../../utils/formatters';
import { BalanceCorrectionEditForm } from './BalanceCorrectionEditForm';
import { EmployeeEditForm } from './EmployeeEditForm';
import { WorkingTimeEditForm } from './WorkingTimeEditForm';

const LineItem = ({ items, className }: any) => {
  return (
    <div className='grid grid-cols-3 gap-0 border-b border-gray-200 py-5'>
      {items.map((item: any, index: number) => (
        <div className={className} key={index}>
          {item}
        </div>
      ))}
    </div>
  );
};

interface EmployeeDetailsProps {
  employee: Employee;
}

const RowDeleteItem = (inProgress: boolean, onClick: (uuid: string) => void): Object => {
  return {
    cell: (props: any) => (
      <button
        className='rounded-full'
        type='button'
        onClick={() => {
          if (!inProgress) {
            onClick(props.row.original.id);
          }
        }}
      >
        {inProgress ? (
          <ArrowPathIcon className='h-5 w-5 text-red-400 animate-spin' aria-hidden='true' />
        ) : (
          <MinusCircleIcon
            className='h-5 w-5 text-transparent group-hover:text-red-600'
            aria-hidden='true'
          />
        )}
      </button>
    ),
    header: '',
    id: 'delete',
    size: 20
  };
};

export const EmployeeDetails = ({ employee }: EmployeeDetailsProps) => {
  const { dispatch } = useContext(SystemUiContext);

  const [allocationRemoval, setAllocationRemoval] = useState<string | null>(null);
  const [allocationRemovalProgress, setAllocationRemovalProgress] = useState<boolean>(false);

  const [balanceCorrectionRemoval, setBalanceCorrectionRemoval] = useState<string | null>(null);
  const [balanceCorrectionRemovalProgress, setBalanceCorrectionRemovalProgress] =
    useState<boolean>(false);

  const { data, loading, refetch } = useEmployeesDetailsQuery({
    variables: {
      where: {
        id: {
          _eq: employee.id
        }
      }
    }
  });

  const [deleteAllocation] = useDeleteEmployeeAllocationMutation({
    onCompleted: (data) => {
      setAllocationRemovalProgress(false);
      refetch();
    },
    onError: (data) => console.error(data)
  });

  const [deleteBalanceCorrection] = useDeleteBalanceCorrectionMutation({
    onCompleted: (data) => {
      setBalanceCorrectionRemovalProgress(false);
      refetch();
    },
    onError: (data) => console.error(data)
  });

  const detailsData = (data?.employee[0] || {}) as Employee;

  const { balance_corrections, employee_allocations } = detailsData || {
    balance_corrections: [],
    employee_allocations: []
  };

  const balanceHelper = createColumnHelper<Balance_Corrections>();
  const allocationHelper = createColumnHelper<Employee_Allocations>();
  const balanceAndWorkingTime = () =>
    loading ? (
      <div className='flex justify-center mt-10'>
        <Spinner />
      </div>
    ) : (
      <div className='grid grid-cols-1 gap-4'>
        <div>
          <ExtendableTable
            title='Balance corrections'
            columns={[
              balanceHelper.accessor((row) => row.day, {
                id: 'day',
                ...dateColumnGenerator('Day')
              }),
              balanceHelper.accessor((row) => row.hours, {
                id: 'hours',
                ...hoursColumnGenerator('Correction')
              }),
              balanceHelper.accessor((row) => row.comment, {
                id: 'comment',
                ...stringColumnGenerator('Comment')
              }),

              RowDeleteItem(balanceCorrectionRemovalProgress, (uuid) =>
                setBalanceCorrectionRemoval(uuid)
              )
            ]}
            data={balance_corrections}
            action={
              <button
                className='mr-5'
                type='button'
                onClick={() => {
                  dispatch?.({
                    type: SystemUiActions.SHOW_DRAWER,
                    payload: {
                      open: true,
                      title: `Change the balance for ${employeeFullname(employee)}`,
                      description:
                        'The value is a correction value, i.e. it is added to the existing balance',
                      content: (
                        <BalanceCorrectionEditForm
                          employee={employee}
                          onComplete={() => refetch()}
                        />
                      )
                    }
                  });
                }}
              >
                <PlusCircleIcon className='h-8 w-8' aria-hidden='true' />
              </button>
            }
          />
          {balance_corrections.length === 0 ? (
            <div className='text-center '>No balance corrections set</div>
          ) : null}
        </div>
        <div>
          <ExtendableTable
            title='Agreed working time'
            columns={[
              allocationHelper.accessor((row) => row.start_date, {
                id: 'start_date',
                ...dateColumnGenerator('Day')
              }),
              allocationHelper.accessor((row) => row.allocation, {
                id: 'allocation',
                ...numberColumnGenerator('Allocation', '%', undefined, undefined, 30)
              }),
              allocationHelper.accessor((row) => row.comment, {
                id: 'comment',
                ...stringColumnGenerator('Comment')
              }),
              allocationHelper.accessor((row) => row.metadata, {
                id: 'metadata',
                ...jsonStringifierColumnGenerator('Meta data')
              }),

              RowDeleteItem(allocationRemovalProgress, (uuid) => setAllocationRemoval(uuid))
            ]}
            data={employee_allocations}
            action={
              <button
                className='mr-5 h-8 w-8 rounded-full hover:bg-royal-dark-blue hover:text-white'
                type='button'
                onClick={() => {
                  dispatch?.({
                    type: SystemUiActions.SHOW_DRAWER,
                    payload: {
                      open: true,
                      title: 'Add working time',
                      description: 'Set the working time of the employee, 60% - 100%',
                      content: (
                        <WorkingTimeEditForm employee={employee} onComplete={() => refetch()} />
                      )
                    }
                  });
                }}
              >
                <PlusCircleIcon className='h-8 w-8' aria-hidden='true' />
              </button>
            }
          />
          {employee_allocations.length === 0 ? (
            <div className='text-center '>
              No working time set, payroll info will not be correct.
            </div>
          ) : null}
        </div>
      </div>
    );

  return (
    <>
      <div className='mb-20'>
        <div className='flex justify-between border-b border-gray-200 pb-4'>
          <div className='text-lg leading-6 font-medium'>Personal information</div>

          <button
            className='inline-flex bg-transparent px-6 border border-transparent text-base font-medium  text-black  hover:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent'
            onClick={() => {
              dispatch?.({
                type: SystemUiActions.SHOW_DRAWER,
                payload: {
                  open: true,
                  title: `Edit ${employeeFullname(employee)}`,
                  description: 'Edit the details of the employee',
                  content: <EmployeeEditForm employee={detailsData} onComplete={() => refetch()} />
                }
              });
            }}
          >
            <PencilSquareIcon className='w-5 h-5 mr-5' />
            <span className='flex-1 text-center'>Edit</span>
          </button>
        </div>
        <LineItem items={['First name', detailsData.first_name]} />
        <LineItem items={['Last name', detailsData.last_name, '']} />
        <LineItem items={['Email address', detailsData.email, '']} />
        <LineItem
          className={detailsData.employment_start === null ? 'text-red-500' : ''}
          items={[
            'Employment start',
            detailsData.employment_start === null
              ? 'No start date set, payroll info will not be correct.'
              : formatDate(detailsData.employment_start),
            ''
          ]}
        />
        <LineItem
          items={[
            'Employment end',
            detailsData.employment_end !== null ? formatDate(detailsData.employment_end) : '',
            ''
          ]}
        />
        <LineItem
          items={[
            'Non billable employee',
            <Toggle
              onChange={() => {}}
              value={{
                selected: detailsData?.metadata?.non_billable_employee,
                id: 'non_billable_employee',
                label: ''
              }}
            />
          ]}
        />
        <LineItem
          items={[
            'External freelancer',
            <Toggle
              onChange={() => {}}
              value={{
                selected: detailsData?.metadata?.freelancer,
                id: 'freelancer',
                label: ''
              }}
            />
          ]}
        />
        <DeleteModal
          isOpen={allocationRemoval != null}
          title='Are you sure you want to delete allocation info from employee?'
          description={'This will have an impact on salaray calculations'}
          onClose={() => setAllocationRemoval(null)}
          onDelete={() => {
            setAllocationRemovalProgress(true);
            deleteAllocation({ variables: { id: allocationRemoval } });
          }}
        />
        <DeleteModal
          isOpen={balanceCorrectionRemoval != null}
          title='Are you sure you want to delete a balance correction from an employee?'
          description={
            'This will have an impact on salaray calculations and the employee current balance'
          }
          onClose={() => setBalanceCorrectionRemoval(null)}
          onDelete={() => {
            setBalanceCorrectionRemovalProgress(true);
            deleteBalanceCorrection({ variables: { id: balanceCorrectionRemoval } });
          }}
        />
      </div>

      {balanceAndWorkingTime()}
    </>
  );
};
